import React from 'react';
import useDarkMode from 'use-dark-mode';

const darkModeToggle = ({ mobile }) => {
  const darkMode = useDarkMode(false);
  return (
    <a className="link" onClick={darkMode.toggle} >
      {/*
      <svg style={{ height: '18px', marginTop: '8px' }} aria-hidden="true" focusable="false" data-prefix="far" data-icon="adjust" role="img" xmlns="https://www.w3.org/2000/svg" viewBox="0 0 512 512" className="svg-inline--fa fa-adjust fa-w-16 fa-5x"><path fill="currentColor" d="M256 56c110.549 0 200 89.468 200 200 0 110.549-89.468 200-200 200-110.549 0-200-89.468-200-200 0-110.549 89.468-200 200-200m0-48C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 96c-83.947 0-152 68.053-152 152s68.053 152 152 152V104z" className=""></path></svg>
      */}
      <svg style={{ height: '18px', marginTop: '8px' }} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="adjust" role="img" xmlns="https://www.w3.org/2000/svg" viewBox="0 0 512 512" className="svg-inline--fa fa-adjust fa-w-16 fa-5x"><path fill="currentColor" d="M8 256c0 136.966 111.033 248 248 248s248-111.034 248-248S392.966 8 256 8 8 119.033 8 256zm248 184V72c101.705 0 184 82.311 184 184 0 101.705-82.311 184-184 184z" className=""></path></svg>
    </a>
  );
};

export default darkModeToggle;
